import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import styled from '@emotion/styled';
import Box from '@mui/system/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import RecentIcon from './RecentIcon';
import TopIcon from './TopIcon';
import RandomIcon from './RandomIcon';
import ModelIcon from './ModelIcon';

const StyledButton = styled(Button)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
  color: #2d2d2d; // Set text color to black
  border-color: #2d2d2d; // Set border color to black

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-color: #2d2d2d; // Set border color to black on hover
  }
`;

const ButtonGroupComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (buttonId) => {
    props.setActiveButton(buttonId);
  };

  const isActiveButton = (buttonId) => {
    return props.activeButton === buttonId;
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event) => {
    props.setCheckboxState({
      ...props.checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box display='flex' justifyContent='center' alignItems='center' marginBottom='20px'>
      <ButtonGroup variant='outlined' color='primary'>
        <StyledButton
          startIcon={<TopIcon />}
          onClick={() => handleClick(2)}
          style={isActiveButton(2) ? { backgroundColor: 'rgba(0, 0, 0, 0.2)' } : {}}
        >
          Top
        </StyledButton>

        <StyledButton
          startIcon={<RecentIcon />}
          onClick={() => handleClick(1)}
          style={isActiveButton(1) ? { backgroundColor: 'rgba(0, 0, 0, 0.2)' } : {}}
        >
          Recent
        </StyledButton>
        {!props.search && (
          <>
            <StyledButton
              startIcon={<RandomIcon />}
              onClick={() => handleClick(3)}
              style={isActiveButton(3) ? { backgroundColor: 'rgba(0, 0, 0, 0.2)' } : {}}
            >
              Random
            </StyledButton>
            <StyledButton startIcon={<ModelIcon />} onClick={handleMenuClick}>
              Model
            </StyledButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                elevation: 3,
                style: {
                  maxHeight: '200px',
                  backgroundColor: '#e6e6e5',
                  width: '140px',
                },
              }}
            >
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.checkboxState.chatgpt3}
                      onChange={handleCheckboxChange}
                      name='chatgpt3'
                      sx={{
                        '&.Mui-checked .MuiSvgIcon-root': {
                          fill: '#6b1e1e',
                        },
                      }}
                    />
                  }
                  label='ChatGPT-3'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.checkboxState.chatgpt4}
                      onChange={handleCheckboxChange}
                      name='chatgpt4'
                      sx={{
                        '&.Mui-checked .MuiSvgIcon-root': {
                          fill: '#6b1e1e',
                        },
                      }}
                    />
                  }
                  label='ChatGPT-4'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.checkboxState.bard}
                      onChange={handleCheckboxChange}
                      name='bard'
                      sx={{
                        '&.Mui-checked .MuiSvgIcon-root': {
                          fill: '#6b1e1e',
                        },
                      }}
                    />
                  }
                  label='Gemini'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.checkboxState.meta}
                      onChange={handleCheckboxChange}
                      name='meta'
                      sx={{
                        '&.Mui-checked .MuiSvgIcon-root': {
                          fill: '#6b1e1e',
                        },
                      }}
                    />
                  }
                  label='Meta'
                />
              </MenuItem>
              <MenuItem>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props.checkboxState.claude}
                      onChange={handleCheckboxChange}
                      name='claude'
                      sx={{
                        '&.Mui-checked .MuiSvgIcon-root': {
                          fill: '#6b1e1e',
                        },
                      }}
                    />
                  }
                  label='Claude'
                />
              </MenuItem>
            </Menu>
          </>
        )}
      </ButtonGroup>
    </Box>
  );
};

export default ButtonGroupComponent;
