import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DasboardIcon, PuzzleIcon, QuestionIcon, LoginIcon, MagnifyingGlassIcon } from '../Logos/Logos';
import { connect } from 'react-redux';
import aiarchiveslogo from '../../assets/images/aiarchives.png';
import { PropTypes } from 'prop-types';
import { firebase_auth } from '../../firestore';
import { createNewUser } from '../../redux/actions/user';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged } from 'firebase/auth';
import $ from 'jquery';
import './HomeHeader.css'; // Import CSS file

var header;

const links = [
  {
    name: 'How to Use',
    icon: <QuestionIcon />,
    dropdown: [
      {
        name: 'Start Here',
        href: 'https://www.youtube.com/watch?v=YdR275fdEUs',
      },
      { name: 'Organize', href: 'https://www.youtube.com/watch?v=so03DTJExZI' },
      { name: 'Cite', href: 'https://www.youtube.com/watch?v=hAFHFkrHocY' },
      { name: 'Browse', href: 'https://www.youtube.com/watch?v=_sfIWbojSNc' },
      { name: 'Policy', href: '/policy.html' },
    ],
  },
  {
    name: 'Get Extension',
    icon: <PuzzleIcon />,
    href: 'https://chrome.google.com/webstore/detail/aiarchives-share-your-cha/jagobfpimhagccjbkchfdilhejgfggna?hl=en',
    external: true,
  },
  {
    name: 'Search',
    icon: <MagnifyingGlassIcon />,
    href: '/s',
    external: false,
  },
  {
    name: 'Login',
    icon: <LoginIcon />,
    onClick: true,
    conditionalDisplay: true,
    needAuth: false,
  },
  {
    name: 'Dashboard',
    icon: <DasboardIcon />,
    href: '/Dashboard',
    conditionalDisplay: true,
    needAuth: true,
  },
];

const HomeHeader = () => {
  const [profile, setProfile] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null); // new state variable

  useEffect(() => {
    onAuthStateChanged(firebase_auth, (user) => {
      setProfile(!!user);
    });
  }, []);

  useEffect(() => {
    header = document.getElementById('header');
  }, []);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    localStorage.setItem('loginRedirect', window.location.pathname);
    signInWithPopup(firebase_auth, provider)
      .then((res) => {
        if (res?.user) {
          createNewUser(res?.user);
        }
      })
      .catch((err) => {
        localStorage.removeItem('loginRedirect');
        console.log('google signIn error', err);
      });
  };

  $(window).on('scroll', function (e) {
    if (header.offsetTop !== 0) {
      if (!header.classList.contains('shadow')) {
        header.classList.add('shadow');
      }
    } else {
      header.classList.remove('shadow');
    }
  });

  return (
    <header
      style={{ backgroundColor: '#80808038', borderRadius: '10px' }}
      className='contain box box-header fixed top-2 left-0 right-0 flex items-center justify-between p-2 z-50 backdrop-blur-xl transition-all'
    >
      <Link
        className='flex items-center rounded-md'
        style={{ backgroundColor: 'transparent', marginLeft: '20px', textDecoration: 'none' }}
        to='/'
      >
        <img src={aiarchiveslogo} className='header-img' alt='logo' />
      </Link>

      {/* links */}
      <ul className='flex items-center pl-2 m-0 list-none md:space-x-1'>
        {links.map((link, i) => (
          <li key={i} style={{ position: 'relative' }}>
            <div
              onMouseEnter={() => {
                setDropdownOpen(true);
                setActiveDropdown(i);
              }}
              onMouseLeave={() => {
                setDropdownOpen(false);
                setActiveDropdown(null);
              }}
            >
              {link.name === 'How to Use' ? (
                <div
                  style={{ color: '#2d2e2d', cursor: 'default' }}
                  className={`${
                    link.conditionalDisplay
                      ? link.needAuth
                        ? profile
                          ? 'flex'
                          : 'hidden'
                        : profile
                        ? 'hidden'
                        : 'flex'
                      : 'flex'
                  } items-center px-3 py-2 text-xs no-underline transition rounded-md md:text-base hover:bg-[#6b72803b]`}
                >
                  {link.icon}
                  <p className='hidden ml-2 sm:block font-semibold text-lg'>{link.name}</p>
                </div>
              ) : link.name !== 'Login' ? (
                <Link
                  to={`${link.href}`}
                  target={link.external ? '_blank' : '_self'}
                  style={{ color: '#2d2e2d' }}
                  className={`${
                    link.conditionalDisplay
                      ? link.needAuth
                        ? profile
                          ? 'flex'
                          : 'hidden'
                        : profile
                        ? 'hidden'
                        : 'flex'
                      : 'flex'
                  } items-center px-3 py-2 text-xs no-underline transition rounded-md md:text-base hover:bg-[#6b72803b]`}
                >
                  {link.icon}
                  <p className='hidden ml-2 sm:block font-semibold text-lg'>{link.name}</p>
                </Link>
              ) : (
                <div
                  onClick={signInWithGoogle}
                  style={{ color: '#2d2e2d', cursor: 'pointer' }}
                  className={`${
                    link.conditionalDisplay
                      ? link.needAuth
                        ? profile
                          ? 'flex'
                          : 'hidden'
                        : profile
                        ? 'hidden'
                        : 'flex'
                      : 'flex'
                  } items-center px-3 py-2 text-xs no-underline transition rounded-md md:text-base hover:bg-[#6b72803b]`}
                >
                  {link.icon}
                  <p className='hidden ml-2 sm:block font-semibold text-lg'>{link.name}</p>
                </div>
              )}
              {link.dropdown && dropdownOpen && activeDropdown === i && (
                <ul
                  className='home-header-dropdown'
                  style={{
                    position: 'absolute',
                    top: '100%',
                    right: '0',
                    backgroundColor: '6b72803b', // Semi-transparent dropdown
                    backdropFilter: 'blur(8px)',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                    zIndex: '1',
                  }}
                >
                  {link.dropdown.map((item, index) => (
                    <li key={index} className='home-header-dropdown-item' style={{ padding: '5px' }}>
                      <Link
                        to={item.href}
                        target='_blank'
                        style={{ color: '#2d2e2d' }}
                        className='home-header-dropdown-link hover:underline'
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
        ))}
      </ul>
    </header>
  );
};

HomeHeader.propTypes = {
  createNewUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
});

export default connect(mapStateToProps, {
  createNewUser,
})(HomeHeader);
