import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import { useParams, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../App.scss';
import { collection, query, orderBy, limit, where, getDocs } from 'firebase/firestore';
import ButtonGroupComponent from '../../components/ButtonGroup/ButtonGroup';
import { db } from '../../firestore.js';
import '../../assets/globals.scss';
import '../../assets/highlight.css';
import HomeHeader from '../../components/Headers/HomeHeader';
import ConversationTag from '../../components/ConversationTag/ConversationTag';
import Card from '../../components/Cards/Card';
import { getDoc, doc } from 'firebase/firestore';
import TrendingTags from '../../components/TrendingTags/TrendingTags';
import UserAutoComplete from '../../components/UserAutoComplete/UserAutoComplete';
import { LoadingAnimation } from '../../components/Animations/Animations';
import InfiniteScroll from 'react-infinite-scroll-component';

const Tag = () => {
  const [tags, setTags] = useState([]);
  let { tag } = useParams();
  let navigate = useNavigate();

  const [showConversationTag, setShowConversationTag] = useState(true);
  const handleChange = () => {
    setShowConversationTag(!showConversationTag);
  };

  const [recent, setRecent] = useState([]);
  const [topPosts, setTopPosts] = useState([]);
  const [recentCache, setRecentCache] = useState([]);
  const [topPostsCache, setTopPostsCache] = useState([]);
  const [initTag, setInitTag] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [activeButton, setActiveButton] = useState(2);
  const [endInfiniteScrl, setEndInfiniteScrl] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (tag) {
      const tags = tag.split('&');
      const formattedTags = [];
      for (let i = 0; i < tags.length; i++) {
        formattedTags.push({ tag: tags[i] });
      }
      setTags(formattedTags);
      setInitTag(true);
    } else {
      setInitTag(true);
    }
  }, [tag]);

  useEffect(() => {
    let newPath = '/s/';
    for (let i = 0; i < tags.length; i++) {
      if (i !== 0) {
        newPath += '&';
      }
      newPath += tags[i].tag;
    }
    navigate(newPath);
  }, [tags]);

  const getRecentPosts = async () => {
    if (tags.length === 0) {
      return;
    }
    const res = [];
    let q;
    if (recentCache.length && !tags.length) {
      setRecent(recentCache);
      setEndInfiniteScrl(false);
      return;
    }
    if (tags.length) {
      q = query(
        collection(db, 'posts'),
        where(
          'topicTag',
          'array-contains-any',
          tags.map((item) => item.tag)
        ),
        orderBy('created', 'desc'),
        limit(100)
      );
    } else {
      q = query(collection(db, 'posts'), orderBy('created', 'desc'), limit(100));
    }

    try {
      const result = await getDocs(q);
      const promises = result.docs.map(async (postDoc) => {
        const userRef = doc(db, 'users', postDoc.data()?.userid);
        return getDoc(userRef);
      });

      const userDocs = await Promise.all(promises);

      for (let i = 0; i < result.docs.length; i++) {
        const postDoc = result.docs[i];
        const docSnap = userDocs[i];
        const tempPhotoURL = docSnap.data().photoURL;

        res.push({
          title: postDoc.data().title,
          id: postDoc.id,
          tags: postDoc.data().tags,
          views: postDoc.data().views,
          photoURL: tempPhotoURL,
          created: postDoc.data().created,
          relatedPosts: postDoc.data().relatedPosts || [],
          suggestedPosts: postDoc.data().suggestedPosts || [],
        });
      }
      if (!tags.length) setRecentCache(res);
      setRecent(res);
      setEndInfiniteScrl(!res.length);
    } catch (error) {
      // Handle error here
      console.error(error);
      setEndInfiniteScrl(false);
    }
  };

  const getTopPosts = async () => {
    if (tags.length === 0) {
      return;
    }
    const res = [];
    let q;
    if (topPostsCache.length && !tags.length) {
      setTopPosts(topPostsCache);
      setEndInfiniteScrl(false);
      return;
    }
    if (tags.length) {
      q = query(
        collection(db, 'posts'),
        where(
          'topicTag',
          'array-contains-any',
          tags.map((item) => item.tag)
        ),
        orderBy('views', 'desc'),
        limit(100)
      );
    } else {
      q = query(collection(db, 'posts'), orderBy('views', 'desc'), limit(100));
    }

    try {
      const result = await getDocs(q);
      const promises = result.docs.map(async (postDoc) => {
        const userRef = doc(db, 'users', postDoc.data()?.userid);
        return getDoc(userRef);
      });

      const userDocs = await Promise.all(promises);

      for (let i = 0; i < result.docs.length; i++) {
        const postDoc = result.docs[i];
        const docSnap = userDocs[i];
        const tempPhotoURL = docSnap.data().photoURL;

        res.push({
          title: postDoc.data().title,
          id: postDoc.id,
          tags: postDoc.data().tags,
          views: postDoc.data().views,
          photoURL: tempPhotoURL,
          created: postDoc.data().created,
          relatedPosts: postDoc.data().relatedPosts || [],
          suggestedPosts: postDoc.data().suggestedPosts || [],
        });
      }

      if (!tags.length) setTopPostsCache(res);
      setTopPosts(res);
      setEndInfiniteScrl(!res.length);
    } catch (error) {
      // Handle error here
      console.error(error);
      setEndInfiniteScrl(false);
    }
  };

  useEffect(() => {
    if (initTag) {
      setRecent([]);
      setTopPosts([]);
      setEndInfiniteScrl(true);
      getRecentPosts();
      getTopPosts();
    }
  }, [tags, initTag]);

  useEffect(() => {
    if (activeButton === 1) {
      setFilteredPosts(recent);
    } else {
      setFilteredPosts(topPosts);
    }
  }, [activeButton, topPosts.length, recent.length]);

  return (
    <div className='Home'>
      <HomeHeader />
      <div className='contain'>
        <div className='content row'>
          <div
            className='submit-box box'
            style={{ borderRadius: '10px', marginBottom: '20px' }} // Add marginBottom style here
          >
            <h1 style={{ margin: '15px 20px' }}>Questions by Humans, Answers by A.I.</h1>
            <h2 style={{ margin: '15px 20px', fontWeight: 'normal' }}>
              Explore a vast collection of conversations through our tag-based system. Want to find something specific?
              Search for ChatGPT and Bard prompts in the Search Bar! And do not miss out on the trending tags below for
              all the popular topics.
            </h2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '20px' }}>
              <div style={{ flexGrow: 1, marginLeft: '20px', marginRight: '20px' }}>
                {showConversationTag ? (
                  <ConversationTag search={true} tags={tags} setTags={setTags} inputBarWidth='100%' />
                ) : (
                  <UserAutoComplete />
                )}
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <small style={{ marginTop: '25px', marginBottom: '10px' }}>
                  {showConversationTag ? 'Tags' : 'Prompts'}
                </small>
                <Switch
                  onChange={handleChange}
                  checked={showConversationTag}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  offColor='#6b1e1e'
                  onColor='#254e70'
                />
              </div>
            </div>

            <TrendingTags />
          </div>
        </div>
        <ButtonGroupComponent setActiveButton={setActiveButton} activeButton={activeButton} search={true} />
        <div style={{ margin: 'auto' }} className='box'>
          <InfiniteScroll
            dataLength={filteredPosts.length} //This is important field to render the next data
            hasMore={endInfiniteScrl && tags.length > 0}
            loader={<LoadingAnimation />}
            endMessage={
              tags.length === 0 ? (
                <>
                  <p style={{ textAlign: 'center' }}>
                    <b>Look up a tag in the search bar!</b>
                  </p>
                  <div style={{ height: '25px' }}></div>
                </>
              ) : (
                <p style={{ textAlign: 'center' }}>
                  <b>You have seen the limit of this category!</b>
                </p>
              )
            }
            style={{ overflow: 'visible' }}
          >
            <div className='steps row'>
              {filteredPosts.map((item, index) => {
                return (
                  <Card
                    key={index}
                    title={item.title}
                    id={item.id}
                    view={false}
                    tags={item.tags}
                    photoURL={item.photoURL}
                    views={item.views}
                    created={item.created}
                    relatedPosts={item.relatedPosts}
                    suggestedPosts={item.suggestedPosts}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default connect()(Tag);
