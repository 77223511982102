import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    border-color: #2d2d2d;
  }
  color: #2d2d2d;
  border-color: #2d2d2d;
  text-align: center;
  padding: 6px 0; // Set left and right padding to 0
`;

const ButtonColumnComponent = () => {
  const handleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '4rem',
        marginBottom: '3rem',
      }}
    >
      <ButtonContainer>
        <StyledButton
          className='btn-models'
          variant='outlined'
          color='primary'
          onClick={() => handleClick('https://chat.openai.com/')}
          style={{ marginRight: '16px' }}
        >
          ChatGPT
        </StyledButton>
        <StyledButton
          className='btn-models'
          variant='outlined'
          color='primary'
          onClick={() => handleClick('https://www.meta.ai/')}
          style={{ marginRight: '16px' }}
        >
          Meta
        </StyledButton>
        <StyledButton
          className='btn-models'
          variant='outlined'
          color='primary'
          onClick={() => handleClick('https://gemini.google.com/')}
          style={{ marginRight: '16px' }}
        >
          Gemini
        </StyledButton>
        <StyledButton
          className='btn-models'
          variant='outlined'
          color='primary'
          onClick={() => handleClick('https://claude.ai')}
        >
          Claude
        </StyledButton>
      </ButtonContainer>
    </Box>
  );
};

export default ButtonColumnComponent;
